export const readCompanyNames = (companyVO: any[]) => {
    if (companyVO.length === 0) {
        return [];
    }
    if (
        companyVO.length !== 1 ||
        !(companyVO[0].companyId === 5000000 && companyVO[0].companyName === "Other")
    ) {
        const companyOptions = companyVO.map((company: { companyName: any; companyId: any }) => {
            return {
                label: company.companyName,
                value: company.companyId,
            };
        });
        companyOptions.unshift({ label: "Select Company", value: "" });
        return companyOptions;
    } else {
        return [];
    }
};

export const OTP_ERROR_MSG = "Verification code is required";
export const UPGRADE = "upgrade";
export const ISUPPLIER = "iSupplier";
export const REGISTRATION = "registration";
export const INVALID_OTP_MSG = `Verification code is invalid. Please enter a correct code.`;
export const COUNTRY_REQUIRED = "Country is required";
export const MAX_ALLOWED_40 = "Maximum allowed characters is 40";
export const GREEN_CIRCLE = "green-circle";
export const GREY_CIRCLE = "grey-circle";
export const NUM_CHAR = "Numbers & special characters are not allowed";
export const SPECIAL_CHAR = "Special characters are not allowed";
export const MIN_2 = "A minimum of 2 characters is required";
