import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";

import { getUserDetails, getUserEmail } from "../../../../../store/CustomerQueryTool/slice";
import MasterDetails from "./master-details/MasterDetails";
import OktaDetails from "./okta-details/OktaDetails";
import SupportHubDetails from "./support-hub-details/SupportHubDetails";
import WolkenDetails from "./wolken-details/WolkenDetails";
import { isInternalUser } from "../../../../../utils/utils"
import { AUTHHUB_LABEL, OKTA_LABEL } from "../../../constants";

const UserDetails = () => {
    const [isInternalUserFlag, setIsInternalUserFlag] = useState("");
    const [authLabel, setAuthLabel] = useState("");

    const userDetails = useSelector(getUserDetails);
    const userEmail = useSelector(getUserEmail);

    useEffect(() => {
        if (userDetails.email) {
            if (isInternalUser(userDetails.email)) {
                setIsInternalUserFlag("Yes");
            } else if (!isInternalUser(userDetails.email)) {
                setIsInternalUserFlag("No");
            }
        }
    }, []);

    useEffect(() => {
        if (isInternalUserFlag === "Yes") {
            setAuthLabel(OKTA_LABEL)
        } else if (isInternalUserFlag === "No") {
            setAuthLabel(AUTHHUB_LABEL)
        }
    }, [isInternalUserFlag]);

    useEffect(() => {
        if (
            userEmail.toLowerCase().includes("broadcom.com") ||
            userEmail.toLowerCase().includes("broadcom.net")
        ) {
            setAuthLabel(OKTA_LABEL);
        } else {
            setAuthLabel(AUTHHUB_LABEL);
        }
    }, [userEmail]);

    return (
        <React.Fragment>
            {userDetails &&
                userDetails.success &&
                (userDetails?.data?.oktaProfile?.errorCode === "E0000007" ||
                    userDetails?.data?.oktaProfile?.email === undefined) && (
                    <div>
                        <h2 className="text-red">{authLabel} Details</h2>
                        <p className="text-red">
                            User is not present in {authLabel}. Please contact Customer support Team
                        </p>
                    </div>
                )}

            {userDetails && userDetails.success && userDetails.data.oktaProfile.email && (
                <ClayCard>
                    <ClayCard.Body>
                        <div id="customerQueryDetails">
                            <ClayLayout.Row justify="start">
                                <ClayLayout.Col size={1}>
                                    <h3 className="text-red">Name:</h3>
                                </ClayLayout.Col>
                                <ClayLayout.Col size={11} className="text-red">
                                    {userDetails.data.oktaProfile.firstName}{" "}
                                    {userDetails.data.oktaProfile.lastName}
                                </ClayLayout.Col>
                                <ClayLayout.Col size={1}>
                                    <h3 className="text-red">Email:</h3>
                                </ClayLayout.Col>
                                <ClayLayout.Col size={11} className="text-red">
                                    {userDetails.data.oktaProfile.email}
                                </ClayLayout.Col>
                            </ClayLayout.Row>
                        </div>

                        <hr />

                        <MasterDetails userDetails={userDetails} />
                        <OktaDetails userDetails={userDetails} />
                        <SupportHubDetails userDetails={userDetails} />
                        <WolkenDetails userDetails={userDetails} />
                    </ClayCard.Body>
                </ClayCard>
            )}
        </React.Fragment>
    );
};

export default UserDetails;
