import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../client-src/store/hook";

import ClayButton from "@clayui/button";
import ClayForm from "@clayui/form";

import { FormControl, RoundTickIcon, TextError } from "../../../../../../common-ui-modules";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    setVerifySupportVal,
    getAttemptCountForSerialNumber,
    getSerialNumberSuccess,
    resetSerialNumberSuccess,
    getInitialValues,
    addressValuesReceived,
    getStateVo,
    getProfileBuilderData,
    userInfoObject,
    fetchStates,
    stateVOReceived,
} from "../../../../../../store/Registration/profile-builder/slice";
import ClayLayout from "@clayui/layout";
import {
    getCountryList,
    getCountryValueList,
} from "../../../../../../store/Registration/registration/slice";
import { KB_LOCATE_SITEID } from "../../constants/profile-builder";
import {
    ATTEMPTS,
    MAX_40,
    MIN_2,
    PHONE_NO_REGEX,
    POSTAL_CODE_REGEX,
    SPECIAL_CHARS,
} from "../../constants";
import { MAX_255 } from "../../../pages/my-profile/UserInformation/constants";
import { checkIfStatePresent } from "../../../../../../utils/utils";

const Form2 = React.forwardRef((props, secondFormRef) => {
    const [isValid, setIsValid] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [initialFormObj, setInitialFormObj] = useState({});
    const [bsnAutoApproved, setBsnAutoApproved] = useState(false);
    const [showSerialNum, setShowSerialNum] = useState(false);
    const [showInvalidMsg, setShowInvalidMsg] = useState(false);

    const dispatch = useAppDispatch();

    const initialObj = useAppSelector(getInitialValues);
    const profileData = useAppSelector(getProfileBuilderData);
    const userInfo = useAppSelector(userInfoObject);
    const stateOptions = useAppSelector(getStateVo);

    const attemptCount = useAppSelector(getAttemptCountForSerialNumber);
    const serialNumberSuccess = useAppSelector(getSerialNumberSuccess);

    const countryOptions = useAppSelector(getCountryList);
    const countryOptionValues = useAppSelector(getCountryValueList);

    useEffect(() => {
        if (serialNumberSuccess !== "") {
            if (!serialNumberSuccess.success) {
                setIsValid(false);
                setShowVerify(false);
                setShowInvalidMsg(true);
                setShowSerialNum(true);
            } else {
                setIsValid(true);
                setShowSerialNum(true);
            }
        } else {
            setIsValid(false);
        }
    }, [serialNumberSuccess]);

    useEffect(() => {
        const state = initialObj.addressValues.state;
        secondFormRef.current.setFieldValue("phone", initialObj.addressValues.phone);
        secondFormRef.current.setFieldValue("companyId", initialObj.addressValues.companyId);
        secondFormRef.current.setFieldValue("companyName", initialObj.addressValues.companyName);
        secondFormRef.current.setFieldValue("address", initialObj.addressValues.address);
        secondFormRef.current.setFieldValue("address2", initialObj.addressValues.address2);
        secondFormRef.current.setFieldValue("city", initialObj.addressValues.city);
        secondFormRef.current.setFieldValue("zipcode", initialObj.addressValues.zipcode);
        secondFormRef.current.setFieldValue(
            "state",
            checkIfStatePresent(stateOptions, state) ? state : "",
        );
        secondFormRef.current.setFieldValue("country", initialObj.addressValues.country);
        secondFormRef.current.setFieldValue(
            "bsnAutoApproved",
            initialObj.addressValues.bsnAutoApproved,
        );
    }, [initialObj]);

    useEffect(() => {
        let obj = {};
        if (profileData[1].data) {
            obj = { ...profileData[1].data };
        } else {
            obj = { serialNumber: "", ...initialObj.addressValues };
        }
        setInitialFormObj(obj);
        setBsnAutoApproved(obj.bsnAutoApproved);
    }, []);

    const validationSchema = Yup.object().shape({
        bsnAutoApproved: Yup.boolean(),
        serialNumber: Yup.string().when("bsnAutoApproved", {
            is: false,
            then: Yup.string().required("*Serial Number is required"),
        }),
        phone: Yup.string()
            .required("*Phone Number is required")
            .min(8, "A minimum of 8 character is required")
            .max(40, MAX_40)
            .matches(PHONE_NO_REGEX, "Phone number is not valid"),
        address: Yup.string().required("*Address is required").min(2, MIN_2).max(255, MAX_255),
        address2: Yup.string()
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ0-9.,\s]+$/, SPECIAL_CHARS),
        city: Yup.string()
            .required("*City is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ\s]+$/, "Numbers & special characters are not allowed"),
        zipcode: Yup.string()
            .required("*Zip/Postal Code is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(POSTAL_CODE_REGEX, SPECIAL_CHARS),
        country: Yup.string()
            .oneOf(countryOptionValues, "Country is required")
            .required("Country is required"),
        companyName: Yup.string().required("*Company is required"),
        companyId: Yup.string().required("*Company is required"),
    });

    const handleCompanyChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ companyName: secondFormRef.current.values.companyName }));
    };
    const handleContactChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ phone: secondFormRef.current.values.phone }));
    };
    const handleBsnAutoApprovedChange = () => {
        secondFormRef.current.setFieldValue("bsnAutoApproved", !bsnAutoApproved);
        secondFormRef.current.setFieldValue("serialNumber", "");
        setIsValid(false);
        setShowVerify(false);
        dispatch(addressValuesReceived({ bsnAutoApproved: !bsnAutoApproved }));
        setBsnAutoApproved(!bsnAutoApproved);
        dispatch(resetSerialNumberSuccess());
    };

    const handleShowSerialNumChange = () => {
        setIsValid(false);
        setShowVerify(false);
        setShowSerialNum(!showSerialNum);
        secondFormRef.current.setFieldValue("serialNumber", "");
        dispatch(resetSerialNumberSuccess());
    };

    const handleAddressChange = (value, formik, line) => {
        formik.handleBlur(value);
        if (line === 2) {
            dispatch(addressValuesReceived({ address2: secondFormRef.current.values.address2 }));
        } else {
            dispatch(addressValuesReceived({ address: secondFormRef.current.values.address }));
        }
    };

    const handleZipChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ zipcode: secondFormRef.current.values.zipcode }));
    };
    const handleCityChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ city: secondFormRef.current.values.city }));
    };
    const handleStateChange = (e, formik) => {
        formik.handleChange(e);
        secondFormRef.current.setFieldValue("state", e.currentTarget.value);
        secondFormRef.current.values["state"] = e.currentTarget.value;
        dispatch(addressValuesReceived({ state: secondFormRef.current.values.state }));
    };
    const handleCompanySelectChange = (e, formik) => {
        formik.handleChange(e);
        secondFormRef.current.setFieldValue("companyId", e.currentTarget.value);
        secondFormRef.current.values.companyId = e.currentTarget.value;
        dispatch(addressValuesReceived({ companyId: secondFormRef.current.values.companyId }));
    };
    const handleOnChangeCountry = (e, formik) => {
        dispatch(stateVOReceived([{ label: "Select State", value: "" }]));
        formik.handleChange(e);
        secondFormRef.current.setFieldValue("state", ""); // changes 1
        secondFormRef.current.setFieldValue("country", e.currentTarget.value);
        secondFormRef.current.setFieldValue(
            "countryName",
            e.currentTarget.options[e.currentTarget.selectedIndex].text,
        );
        dispatch(addressValuesReceived({ country: e.currentTarget.value }));
        dispatch(
            addressValuesReceived({
                countryName: e.currentTarget.options[e.currentTarget.selectedIndex].text,
            }),
        );
        dispatch(fetchStates(e.currentTarget.value));
    };
    const verifySupportVal = () => {
        const serialNumber = secondFormRef.current.values.serialNumber.trim();
        secondFormRef.current.setFieldValue("serialNumber", serialNumber);
        if (!serialNumber) {
            secondFormRef.current.setFieldError("serialNumber", "*Serial Number is required");
            return;
        }

        const bsnParamsVO = {
            firstName: userInfo.firstName ? userInfo.firstName : "",
            lastName: userInfo.lastName ? userInfo.lastName : "",
            phone: userInfo.phone ? userInfo.phone : "",
            countryId: userInfo.country
                ? userInfo.country.countryCode
                    ? userInfo.country.countryCode
                    : userInfo.country
                : "",
            companyId: userInfo.companyId ? userInfo.companyId : "",
            addressLine1: userInfo.addressLine1 ? userInfo.addressLine1 : "",
            addressLine2: userInfo.addressLine2 ? userInfo.addressLine2 : "",
        };
        const data = {
            supportTypeId: "5",
            supportTypeVal: serialNumber,
            bsnParamsVO: bsnParamsVO,
            emailId: userInfo.email ? userInfo.email : "",
        };
        dispatch(setVerifySupportVal(data, "serialNumber"));
    };

    const validateStateIfPresent = (value) => {
        let errors = "";
        if (
            (stateOptions.length > 1 && value === "") ||
            (stateOptions.length > 1 && value && !stateOptions.map((i) => i.value).includes(value))
        ) {
            errors = "State is required.";
        }
        return errors;
    };

    const handleKeyDown = () => {
        setShowInvalidMsg(false);
        if (attemptCount > 0) {
            setShowVerify(true);
            dispatch(resetSerialNumberSuccess());
        }
    };
    const attempts = ATTEMPTS;
    return (
        <React.Fragment>
            <Formik
                innerRef={secondFormRef}
                enableReinitialize={"true"}
                destroyOnUnmount={"false"}
                initialValues={initialFormObj}
                validationSchema={validationSchema}
                onSubmit={() => {
                    /**/
                }}
            >
                {(formik) => (
                    <ClayForm onSubmit={formik.handleSubmit}>
                        <ClayLayout.Row className="supportSiteIdrow">
                            <ClayLayout.Col size={10}>
                                <FormControl
                                    control="checkbox"
                                    name="bsnAutoApproved"
                                    label="Tool Access Only: Licensing, Education, Reporting"
                                    //checked={bsnAutoApproved}
                                    onChange={handleBsnAutoApprovedChange}
                                />
                            </ClayLayout.Col>
                        </ClayLayout.Row>
                        <ClayLayout.Row className="supportSiteIdrow">
                            <ClayLayout.Col size={10}>
                                <FormControl
                                    control="checkbox"
                                    name="serialNumCheckbox"
                                    label="Customer Access: Cases, Downloads, Knowledge and more"
                                    checked={showSerialNum}
                                    onChange={handleShowSerialNumChange}
                                />
                            </ClayLayout.Col>
                        </ClayLayout.Row>
                        {showSerialNum && (
                            <React.Fragment>
                                <ClayLayout.Row className="supportSiteIdrow">
                                    <ClayLayout.Col size={12}>
                                        <label htmlFor="siteId">
                                            Enter Serial number and click verify
                                        </label>
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                                <ClayLayout.Row className="">
                                    <ClayLayout.Col size={10}>
                                        <FormControl
                                            control="text"
                                            type="text"
                                            name="serialNumber"
                                            autoComplete={"off"}
                                            placeholder="Enter the serial number"
                                            onKeyDown={handleKeyDown}
                                            onPaste={handleKeyDown}
                                            disabled={
                                                bsnAutoApproved
                                                    ? true
                                                    : attemptCount <= 0
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {formik.errors.serialNumber
                                            ? null
                                            : attemptCount !== 3 &&
                                              !isValid &&
                                              !bsnAutoApproved &&
                                              showInvalidMsg && (
                                                  <div className="has-error">
                                                      <TextError>
                                                          {serialNumberSuccess.errorMsg}{" "}
                                                          {serialNumberSuccess.response}
                                                          {attemptCount === 2
                                                              ? attempts[0]
                                                              : attemptCount === 1
                                                              ? attempts[1]
                                                              : attemptCount === 0 && attempts[2]}
                                                      </TextError>
                                                  </div>
                                              )}
                                    </ClayLayout.Col>
                                    <ClayLayout.Col size={4} className="">
                                        {showVerify && !isValid && !bsnAutoApproved ? (
                                            <ClayButton
                                                displayType="primary"
                                                onClick={verifySupportVal}
                                            >
                                                {"Verify"}
                                            </ClayButton>
                                        ) : isValid ? (
                                            <RoundTickIcon color="success" />
                                        ) : serialNumberSuccess === null ? (
                                            ""
                                        ) : (
                                            ""
                                        )}
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                            </React.Fragment>
                        )}
                        <div style={{ margin: "10px 0 8px", display: "block" }}>
                            <a
                                href={KB_LOCATE_SITEID}
                                target="_blank"
                                style={{ color: "#005C8A" }}
                                rel="noreferrer"
                            >
                                Need help locating your Support Serial Number?
                            </a>
                        </div>
                        <div style={{ margin: "10px 0 8px", display: "block" }}>
                            <p style={{ color: "#FF0000" }}>*Click Submit below to proceed</p>
                        </div>
                        {initialObj &&
                        initialObj.company &&
                        initialObj.company.fieldType === "select" ? (
                            <FormControl
                                control="select"
                                name="companyId"
                                label="Company"
                                options={initialObj.company.options}
                                onChange={(e) => handleCompanySelectChange(e, formik)}
                                disabled={
                                    initialObj &&
                                    initialObj.company &&
                                    initialObj.company.isDisabled
                                }
                            />
                        ) : (
                            <FormControl
                                control="text"
                                name="companyName"
                                label="Company"
                                onBlur={(e) => handleCompanyChange(e, formik)}
                                disabled={
                                    initialObj &&
                                    initialObj.company &&
                                    initialObj.company.isDisabled
                                }
                            />
                        )}
                        <FormControl
                            control="text"
                            type="text"
                            name="phone"
                            label="Phone Number"
                            autoComplete={"off"}
                            placeholder="Enter the contact number"
                            onBlur={(e) => handleContactChange(e, formik)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="address"
                            label="Address Line 1"
                            autoComplete={"off"}
                            placeholder="Enter the address line 1"
                            onBlur={(e) => handleAddressChange(e, formik)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="address2"
                            label="Address Line 2"
                            autoComplete={"off"}
                            placeholder="Enter the address line 2"
                            onBlur={(e) => handleAddressChange(e, formik, 2)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="city"
                            label="City"
                            autoComplete={"off"}
                            placeholder="Enter the city name"
                            onBlur={(e) => handleCityChange(e, formik)}
                        />
                        <FormControl
                            control="select"
                            name="country"
                            label="Country"
                            options={countryOptions}
                            onChange={(e) => handleOnChangeCountry(e, formik)}
                            onBlur={() => formik.setFieldTouched("country", true)}
                        />
                        {stateOptions.length > 1 && (
                            <FormControl
                                control="select"
                                name="state"
                                label="State"
                                autoComplete={"off"}
                                options={stateOptions}
                                validate={validateStateIfPresent}
                                onChange={(e) => handleStateChange(e, formik)}
                            />
                        )}
                        <FormControl
                            control="text"
                            type="text"
                            name="zipcode"
                            label="Zip/Postal Code"
                            autoComplete={"off"}
                            placeholder="Enter the zip/postal code"
                            onBlur={(e) => handleZipChange(e, formik)}
                        />
                    </ClayForm>
                )}
            </Formik>
        </React.Fragment>
    );
});
Form2.displayName = "Form2";
export default Form2;
