import React,{ useRef }  from "react";

import ClayCard from "@clayui/card";

import Form1 from "./profile-builder/form1";
import Form2 from "./profile-builder/form2";
import Form3 from "./profile-builder/form3";
import Form4 from "./profile-builder/form4";
import Form5 from "./profile-builder/form5";
import Form6 from "./profile-builder/form6";
import SubmitPanel from "./profile-builder/SubmitPanel";
import FormPanel from "./profile-builder/FormPanel";
import { ISetstepFunc } from "./interfaces/interfaces";

const Step5 = React.forwardRef((props:ISetstepFunc, refs) => {
    const firstFormRef = useRef();
    const secondFormRef = useRef();
    const thirdFormRef = useRef();
    const fourthFormRef = useRef();
    const fifthFormRef = useRef();
    const sixthFormRef = useRef();
    const ProfileBuilder = () => {
        return (
            <React.Fragment>
                <FormPanel idx={0}>
                    <Form1 ref={firstFormRef} />
                </FormPanel>
                <FormPanel idx={1}>
                    <Form2 ref={secondFormRef} />
                </FormPanel>
                <FormPanel idx={2}>
                    <Form3 ref={thirdFormRef} />
                </FormPanel>
                <FormPanel idx={3}>
                    <Form4 ref={fourthFormRef} />
                </FormPanel>
                <FormPanel idx={4}>
                    <Form5 ref={fifthFormRef} />
                </FormPanel>
                <FormPanel idx={5}>
                    <Form6 ref={sixthFormRef} />
                </FormPanel>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <ClayCard className="card1">
                <ClayCard.Body>
                    <span className="h1">Build your Profile</span>

                    <div className="text-size-md">
                        {"Complete your profile for access to more Broadcom products and services"}
                    </div>
                </ClayCard.Body>
            </ClayCard>

            <ClayCard className="card2">
                <ClayCard.Body>
                    <p className="text-size-lg">I am interested in:</p>
                    <ProfileBuilder />

                    <br />
                    <SubmitPanel
                        setStep={props.setStep}
                        ref={{
                            firstFormRef,
                            secondFormRef,
                            thirdFormRef,
                            fourthFormRef,
                            fifthFormRef,
                            sixthFormRef
                        }}
                    />
                </ClayCard.Body>
            </ClayCard>
        </React.Fragment>
    );
});
Step5.displayName = 'Step5';

export default Step5;
