/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../client-src/store/hook";

import ClayButton from "@clayui/button";
import ClayForm from "@clayui/form";
import { FormControl, RoundTickIcon, TextError } from "../../../../../../common-ui-modules";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    setVerifySupportVal,
    getSupportValSuccess,
    resetSupportValSuccess,
    getAttemptCountForSupportSiteId,
    getInitialValues,
    addressValuesReceived,
    getProfileBuilderData,
    // getTimezoneList,
    // getTimezoneValueList,
} from "../../../../../../store/Registration/profile-builder/slice";
import ClayLayout from "@clayui/layout";
import { PHONE_NO_REGEX } from "../../constants";
import { getEnvVariables } from "../../../../../../store/global/global.slice";
// import { useSelector } from "react-redux";

const Form1 = React.forwardRef((props, firstFormRef) => {
    const [showVerify, setShowVerify] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [initialFormObj, setInitialFormObj] = useState({});
    const [showInvalidMsg, setShowInvalidMsg] = useState(false);
    const [inputFieldcheck, setinputFieldcheck] = useState(false);

    const dispatch = useAppDispatch();
    const envVariables = useAppSelector(getEnvVariables);

    const initialObj = useAppSelector(getInitialValues);
    const profileData = useAppSelector(getProfileBuilderData);
    
    // const timezoneOptions =useSelector(getTimezoneList)
    // const timezoneValuelist =useSelector(getTimezoneValueList)
    // const timezoneInfo="Your preferred timezone will be used for case routing and correspondence with our support teams"

    const attemptCount = useAppSelector(getAttemptCountForSupportSiteId);
    const supportSiteIdSuccess = useAppSelector(getSupportValSuccess);
    const MY_SITEID_URL = envVariables?.ui.supportPortal + "/web/ecx/my-site-id";
    useEffect(() => {
        if (supportSiteIdSuccess === "" || supportSiteIdSuccess === null) {
            setIsValid(false);
            setShowVerify(false);
            setShowInvalidMsg(true);
        } else {
            setIsValid(true);
        }
    }, [supportSiteIdSuccess]);

    useEffect(() => {
        firstFormRef.current.setFieldValue("phone", initialObj.addressValues.phone);
        // initialObj.addressValues.timezone?firstFormRef.current.setFieldValue("timezone", initialObj.addressValues.timezone):firstFormRef.current.setFieldValue("timezone", "");
    }, [initialObj]);

    useEffect(() => {
        let obj = {};
        if (profileData[0].data) {
            obj = { ...profileData[0].data };
        } else {
            obj = { siteId: "", phone: initialObj.addressValues.phone };
        }
        setInitialFormObj(obj);
    }, []);
    const validationSchema = Yup.object().shape({
        siteId: Yup.string().required("*Site ID is required"),
        // .test("test-siteId", "Verify with correct Site Id to proceed",
        //   function(value) {
        //       if(supportSiteIdSuccess === '' || supportSiteIdSuccess === null){
        //         return false
        //       }else{
        //         return true
        //       }
        //   }),
        phone: Yup.string()
            .required("*Phone Number is required")
            .min(8, "A minimum of 8 character is required")
            .max(40, "Maximum allowed characters is 40")
            .matches(PHONE_NO_REGEX, "Phone number is not valid"),
        // timezone: Yup.string()
        // .oneOf(timezoneValuelist, "*Timezone is required")
        // .required("*Timezone is required"),
    });
    const handleContactChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ phone: firstFormRef.current.values.phone }));
        setinputFieldcheck(true)
    };
    const verifySupportVal = () => {
        const siteID = firstFormRef.current.values.siteId.trim();
        const data = { supportTypeId: "1", supportTypeVal: siteID };
        firstFormRef.current.setFieldValue("siteId", siteID);
        dispatch(setVerifySupportVal(data, "supportSiteId"));
    };
    const handleKeyDown = () => {
        setShowInvalidMsg(false);
        if (attemptCount !== 0) {
            setShowVerify(true);
            dispatch(resetSupportValSuccess());
        }
    };

    // const handleOnChangeTimezone = (e, formik) => { 
    //     firstFormRef.current.setFieldValue("timezone", e.currentTarget.value);
    //     dispatch(addressValuesReceived({ timezone: e.currentTarget.value }));
    //     setinputFieldcheck(true)
    // };
    
    const attempts = [
        "Invalid Site ID. 2 attempts remaining",
        "Invalid Site ID. 1 attempt remaining",
        "Sorry no attempts remaining",
    ];
    return (
        <React.Fragment>
            <Formik
                innerRef={firstFormRef}
                enableReinitialize={"true"}
                destroyOnUnmount={"false"}
                initialValues={initialFormObj}
                validationSchema={validationSchema}
                onSubmit={() => {
                    /**/
                }}
            >
                {(formik) => (
                    <ClayForm onSubmit={formik.handleSubmit}>
                        <ClayLayout.Row className="supportSiteIdrow">
                            <ClayLayout.Col size={8}>
                                <label htmlFor="siteId">Site ID</label>
                            </ClayLayout.Col>
                        </ClayLayout.Row>
                        <ClayLayout.Row className="">
                            <ClayLayout.Col size={8}>
                                <FormControl
                                    control="text"
                                    type="text"
                                    name="siteId"
                                    autoComplete={"off"}
                                    placeholder="Enter Site ID"
                                    onKeyDown={handleKeyDown}
                                    onPaste={handleKeyDown}
                                    disabled={attemptCount === 0 ? true : false}
                                />
                                {formik.errors.siteId
                                    ? null
                                    : attemptCount !== 3 &&
                                      showInvalidMsg && (
                                          <div className="has-error">
                                              <TextError>
                                                  {attemptCount === 2
                                                      ? attempts[0]
                                                      : attemptCount === 1
                                                      ? attempts[1]
                                                      : attemptCount === 0 && attempts[2]}
                                              </TextError>
                                          </div>
                                      )}
                            </ClayLayout.Col>
                            <ClayLayout.Col
                                size={4}
                                className=""
                                style={{ lineHeight: "1.9375rem" }}
                            >
                                {showVerify && !isValid ? (
                                    <ClayButton
                                        displayType="primary"
                                        onClick={() => verifySupportVal()}
                                    >
                                        {"Verify"}
                                    </ClayButton>
                                ) : isValid ? (
                                    <RoundTickIcon color="success" />
                                ) : supportSiteIdSuccess === null ? (
                                    ""
                                ) : (
                                    ""
                                )}
                            </ClayLayout.Col>
                        </ClayLayout.Row>

                        <div style={{ margin: "10px 0 8px", display: "block" }}>
                            <a
                                href={MY_SITEID_URL}
                                target="_blank"
                                style={{ color: "#005C8A" }}
                                rel="noreferrer"
                            >
                                Need help locating your Site ID
                            </a>
                        </div>
                        <FormControl
                            control="text"
                            type="text"
                            name="phone"
                            label="Phone Number"
                            autoComplete={"off"}
                            placeholder="Enter Phone Number"
                            onBlur={(e) => handleContactChange(e, formik)}
                            //disabled={(initialObj.isReturningUser && initialObj.addressValues.phone)}
                        />
                        {/* <ClayLayout.Row>
                            <ClayLayout.Col>
                            <FormControl
                                control="select"
                                name="timezone"
                                label={"Preferred Timezone"}
                                autoComplete={"off"}
                                options={timezoneOptions}
                                onChange={(e) => handleOnChangeTimezone(e, formik)}
                                showInfo={true}
                                infotext={timezoneInfo}
                            />
                             {isValid&& initialFormObj.phone!=="" && formik.errors.timezone && !inputFieldcheck
                                    ? <div className="has-error">
                                    <TextError>{"*Timezone is required"}</TextError>
                                </div>
                                    : null
                                      }
                            </ClayLayout.Col>
                        </ClayLayout.Row> */}
                    </ClayForm>
                )}
            </Formik>
        </React.Fragment>
    );
});

export default Form1;
