import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../client-src/store/hook";

import ClayForm from "@clayui/form";
import { FormControl } from "../../../../../../common-ui-modules";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    getInitialValues,
    addressValuesReceived,
    getStateVo,
    fetchStates,
    stateVOReceived,
} from "../../../../../../store/Registration/profile-builder/slice";
import {
    getCountryList,
    getCountryValueList,
} from "../../../../../../store/Registration/registration/slice";
import { MAX_40, MIN_2, POSTAL_CODE_REGEX, SPECIAL_CHARS } from "../../constants";
import { MAX_255 } from "../../../pages/my-profile/UserInformation/constants";
import { checkIfStatePresent } from "../../../../../../utils/utils";

const Form3 = React.forwardRef((props, thirdFormRef) => {
    const dispatch = useAppDispatch();

    const initialObj = useAppSelector(getInitialValues);
    let stateOptions: any = [];
    stateOptions = useAppSelector(getStateVo);
    const countryOptions = useAppSelector(getCountryList);
    const countryOptionValues = useAppSelector(getCountryValueList);
    useEffect(() => {
        const state = initialObj.addressValues.state;
        thirdFormRef.current.setFieldValue("phone", initialObj.addressValues.phone);
        thirdFormRef.current.setFieldValue("companyId", initialObj.addressValues.companyId);
        thirdFormRef.current.setFieldValue("companyName", initialObj.addressValues.companyName);
        thirdFormRef.current.setFieldValue("address", initialObj.addressValues.address);
        thirdFormRef.current.setFieldValue("address2", initialObj.addressValues.address2);
        thirdFormRef.current.setFieldValue("city", initialObj.addressValues.city);
        thirdFormRef.current.setFieldValue("zipcode", initialObj.addressValues.zipcode);
        thirdFormRef.current.setFieldValue(
            "state",
            checkIfStatePresent(stateOptions, state) ? state : "",
        );
        thirdFormRef.current.setFieldValue("country", initialObj.addressValues.country);
    }, [initialObj]);

    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const validationSchema = Yup.object().shape({
        phone: Yup.string()
            .required("*Phone Number is required")
            .min(8, "A minimum of 8 character is required")
            .max(40, MAX_40)
            .matches(phoneRegExp, "Phone number is not valid"),
        address: Yup.string().required("*Address is required").min(2, MIN_2).max(255, MAX_255),
        address2: Yup.string()
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ0-9.,\s]+$/, SPECIAL_CHARS),
        city: Yup.string()
            .required("*City is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ\s]+$/, "Numbers & special characters are not allowed"),
        zipcode: Yup.string()
            .required("*Zip/Postal Code is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(POSTAL_CODE_REGEX, SPECIAL_CHARS),
        country: Yup.string()
            .oneOf(countryOptionValues, "Country is required")
            .required("Country is required"),
        companyName: Yup.string().required("*Company is required"),
        companyId: Yup.string().required("*Company is required"),
    });

    const handleCompanyChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ companyName: thirdFormRef.current.values.companyName }));
    };
    const handleContactChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ phone: thirdFormRef.current.values.phone }));
    };

    const handleAddressChange = (value, formik, line) => {
        formik.handleBlur(value);
        if (line === 2) {
            dispatch(addressValuesReceived({ address2: thirdFormRef.current.values.address2 }));
        } else {
            dispatch(addressValuesReceived({ address: thirdFormRef.current.values.address }));
        }
    };

    const handleZipChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ zipcode: thirdFormRef.current.values.zipcode }));
    };
    const handleCityChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ city: thirdFormRef.current.values.city }));
    };
    const handleStateChange = (e, formik) => {
        formik.handleChange(e);
        thirdFormRef.current.setFieldValue("state", e.currentTarget.value);
        dispatch(addressValuesReceived({ state: e.currentTarget.value }));
    };
    const handleCompanySelectChange = (e, formik) => {
        formik.handleChange(e);
        thirdFormRef.current.setFieldValue("companyId", e.currentTarget.value);
        dispatch(addressValuesReceived({ companyId: e.currentTarget.value }));
    };
    const handleOnChangeCountry = (e, formik) => {
        dispatch(stateVOReceived([{ label: "Select State", value: "" }]));
        formik.handleChange(e);
        thirdFormRef.current.setFieldValue("stateName", "");
        thirdFormRef.current.setFieldValue("country", e.currentTarget.value);
        thirdFormRef.current.setFieldValue(
            "countryName",
            e.currentTarget.options[e.currentTarget.selectedIndex].text,
        );
        dispatch(addressValuesReceived({ country: e.currentTarget.value }));
        dispatch(
            addressValuesReceived({
                countryName: e.currentTarget.options[e.currentTarget.selectedIndex].text,
            }),
        );
        dispatch(fetchStates(e.currentTarget.value));
    };
    const validateStateIfPresent = (value) => {
        let errors = "";
        if (
            (stateOptions.length > 1 && value === "") ||
            (stateOptions.length > 1 && value && !stateOptions.map((i) => i.value).includes(value))
        ) {
            errors = "State is required.";
        }
        return errors;
    };
    return (
        <React.Fragment>
            <Formik
                innerRef={thirdFormRef}
                enableReinitialize={"true"}
                destroyOnUnmount={"false"}
                initialValues={initialObj.addressValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    /**/
                }}
            >
                {(formik) => (
                    <ClayForm onSubmit={formik.handleSubmit}>
                        {initialObj &&
                        initialObj.company &&
                        initialObj.company.fieldType === "select" ? (
                            <FormControl
                                control="select"
                                name="companyId"
                                label="Company"
                                options={initialObj.company.options}
                                onChange={(e) => handleCompanySelectChange(e, formik)}
                                disabled={
                                    initialObj &&
                                    initialObj.company &&
                                    initialObj.company.isDisabled
                                }
                            />
                        ) : (
                            <FormControl
                                control="text"
                                name="companyName"
                                label="Company"
                                onBlur={(e) => handleCompanyChange(e, formik)}
                                disabled={
                                    initialObj &&
                                    initialObj.company &&
                                    initialObj.company.isDisabled
                                }
                            />
                        )}
                        <FormControl
                            control="text"
                            type="text"
                            name="phone"
                            label="Phone Number"
                            autoComplete={"off"}
                            placeholder="Enter the contact number"
                            onBlur={(e) => handleContactChange(e, formik)}
                        />
                        <FormControl
                            control="text"
                            type="text"
                            name="address"
                            label="Address Line 1"
                            autoComplete={"off"}
                            placeholder="Enter the address line 1"
                            onBlur={(e) => handleAddressChange(e, formik)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="address2"
                            label="Address Line 2"
                            autoComplete={"off"}
                            placeholder="Enter the address line 2"
                            onBlur={(e) => handleAddressChange(e, formik, 2)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="city"
                            label="City"
                            autoComplete={"off"}
                            placeholder="Enter the city name"
                            onBlur={(e) => handleCityChange(e, formik)}
                        />
                        <FormControl
                            control="select"
                            name="country"
                            label="Country"
                            options={countryOptions}
                            onChange={(e) => handleOnChangeCountry(e, formik)}
                            onBlur={() => formik.setFieldTouched("country", true)}
                        />
                        {stateOptions.length > 1 && (
                            <FormControl
                                control="select"
                                name="state"
                                label="State"
                                autoComplete={"off"}
                                options={stateOptions}
                                validate={validateStateIfPresent}
                                onChange={(e) => handleStateChange(e, formik)}
                            />
                        )}
                        <FormControl
                            control="text"
                            type="text"
                            name="zipcode"
                            label="Zip/Postal Code"
                            autoComplete={"off"}
                            placeholder="Enter the zip/postal code"
                            onBlur={(e) => handleZipChange(e, formik)}
                        />
                    </ClayForm>
                )}
            </Formik>
        </React.Fragment>
    );
});
Form3.displayName = "Form3";
export default Form3;
