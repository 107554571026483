import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import UserValidationModel from "../../common/model/UserValidationModel";

import Step4 from "../registration/steps/step4";
import Step5 from "../../common/steps/step5";
import Step6 from "../../common/steps/step6";
import {
    getUserInfo,
    userInfoObject,
    fetchStates,
    getjobTitleArr,
    portletPathReceived,
    getProfilePath,
    getProfileType,
    getServiceArrayData,
    // fetchTimezone,
} from "../../../../../store/Registration/profile-builder/slice";

import {
    loadAllMaster,
    portletPathForRegReceived,
} from "../../../../../store/Registration/registration/slice";
import CommonLayout from "../../../../../components/CommonLayout/CommonLayout";
import { getEnvVariables, getUserDetails } from "../../../../../store/global/global.slice";
import "./ProfileBuilder.scss";
import { ROUTE_PATHS } from "../../../../../utils/constants/routeConstants";
import { isLoggedIn, isInternalUser } from "../../../../../utils/utils";
import { UPGRADE } from "../registration/steps/constants";
import { BUILD_PROFILE_TAB } from "../../../../../utils/constants/constants";
import { AccessDenied } from "../../../../../common-ui-modules/components/AccessDenied/AccessDenied";

const ProfileBuilder = () => {
    const pathname = window.location.pathname;
    const [step, setStep] = useState(pathname === ROUTE_PATHS.UPGRADE ? 4 : 5);
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    document.title = BUILD_PROFILE_TAB;

    const profileType = useSelector(getProfileType);
    const envVariables = useSelector(getEnvVariables);
    const isLogIn = isLoggedIn(envVariables);
    const serviceArray = useSelector(getServiceArrayData);

    let userInfo = {};
    userInfo = useSelector(userInfoObject);

    let profilePath = "";
    profilePath = useSelector(getProfilePath);

    const userDetails = useSelector(getUserDetails);

    useEffect(() => {
        if (profilePath === UPGRADE) {
            dispatch(getUserInfo());
            dispatch(getjobTitleArr());
            dispatch(loadAllMaster());
            // dispatch(fetchTimezone());
        }
    }, [profilePath]);

    useEffect(() => {
        if (userInfo.country) {
            dispatch(fetchStates(userInfo.country));
        }
    }, [userInfo]);
    useEffect(() => {
        dispatch(portletPathReceived(UPGRADE));
        dispatch(portletPathForRegReceived(UPGRADE));
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const renderStep = () => {
        if (step === 4) {
            return <Step4 type={profileType} data={serviceArray} setStep={setStep} email={userInfo?.email} userInfo={userInfo}/>;
        } else if (step === 5) {
            return <Step5 setStep={setStep} />;
        } else if (step === 6) {
            return <Step6 setStep={setStep} />;
        } else if (step === 7) {
            return <Step4 type={profileType} data={serviceArray} />;
        }
    };

    const isBNSAssitOEMUser = userInfo?.isBSNAssistUser || false;
    const isInternal = isInternalUser(userInfo?.email || "");

    return (
        <React.Fragment>
            { isInternal ? (
                <div className="col-md-12 error-banner">
                    <h1 className="error-title">Permission Denied</h1>
                    <p className="error-text">
                        Profile Upgrade not allowed for Internal Users.
                    </p>
                </div>
            ) : (
                isBNSAssitOEMUser ? (
                    <AccessDenied />
                ) : (
                    <React.Fragment>
                        <CommonLayout
                            pageTitle="Build your Profile"
                            description="Complete your profile for access to more Broadcom products and
                                    services"
                        >
                            <div className={`step-page step${step}`}>{renderStep()}</div>
                        </CommonLayout>
                        {visible && <UserValidationModel visible={visible} setVisible={setVisible} />}
                    </React.Fragment>
                )
            )}
        </React.Fragment>
    );
};

export default ProfileBuilder;
