import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../common-ui-modules/service/redux-helper/api-action-creator";
import { CUSTOMER_QUERY_TOOL_API_URL } from "./constants";

const slice = createSlice({
    name: "customerQueryTool",
    initialState: {
        userDetails: [],
        userEmail: '',
    },
    reducers: {
        userDetailsReceived: (state, action) => {
            state.userDetails = action.payload;
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        }
    },
});

export const { 
    userDetailsReceived,
    setUserEmail
} = slice.actions;

//custom actions

export const loadUserDetails = (email: string) => (dispatch: any) => {
    const encodedEmail = encodeURIComponent(email);
    dispatch(setUserEmail(email));
    return dispatch(
        apiCallBegan({
            url: `${CUSTOMER_QUERY_TOOL_API_URL.CUSTOMER_QUERY}?emailId=${encodedEmail}`,
            onSuccess: userDetailsReceived.type,
            method: "GET",
        }),
    );
};


//selectors
export const getUserDetails = (state: any) => state.customerQueryTool.userDetails;
export const getUserEmail = (state: any) => state.customerQueryTool.userEmail;


// reducer
export const customerQueryToolReducer = slice.reducer;
