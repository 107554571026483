import React from "react";
import { useLocation } from "react-router";
import { ROUTE_PATHS } from "../../utils/constants/routeConstants";
import ProfileBuilder from "./ui/pages/profile-builder/ProfileBuilder";

const Registration = React.lazy(() => import("./ui/pages/registration"));
const MyProfile = React.lazy(() => import("./ui/pages/my-profile/MyProfile"));

// Allowing TypeScript to read properties on 'window' object
declare global {
    interface Window {
        mtcaptcha?: any;
    }
}

const getPage = function (pageType: string) {
    switch (pageType) {
        case ROUTE_PATHS.REGISTRATION:
            return <Registration />;
        case ROUTE_PATHS.UPGRADE:
            return <ProfileBuilder />;
        case ROUTE_PATHS.PROFILE:
            return <MyProfile />;
        case ROUTE_PATHS.LOGIN_CALLBACK:
            return (window.location.href = ROUTE_PATHS.PROFILE);
        case ROUTE_PATHS.HOME:
            return (window.location.href = ROUTE_PATHS.REGISTRATION);
        default:
            return <Registration />;
    }
};

const RegistrationPage = () => {
    const location = useLocation();
    return <React.Fragment>{getPage(location.pathname)}</React.Fragment>;
};

export default RegistrationPage;
