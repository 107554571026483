import React, { useEffect, useRef, useState } from "react";
import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayButton from "@clayui/button";
import { Formik } from "formik";
import * as Yup from "yup";
import MTCaptcha from "../../../common/captcha/MTCaptcha";
import FormikControl from "../../../common/form/FormikControl";
import CommonLayout from "../../../../../../components/CommonLayout/CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import {
    getModalOpenStatus,
    getSendOtp,
    getUserValidationInfo,
    loadSendOtp,
    loadValidateUser,
    updateModalStatus,
} from "../../../../../../store/Registration/forgot-password/slice";
import {
    FEDERATED_USER,
    FEDERATED_USER_MSG,
    INTERNAL_USER,
    INTERNAL_USER_MSG,
    USER_MFA_EXCLUDED,
    USER_MFA_EXCLUDED_MSG,
} from "../constants";
import ModelPopUp from "../../../common/model/ModelPopUp";
import "../forgotPassword.scss";
import { isInternalUser,isVmWareUser } from "../../../../../../utils/utils";
import { FP_TAB } from "../../../../../../utils/constants/constants";

const ForgotPassword = (props: any) => {
    const [userType, setUserType] = useState("");
    const { email, captchaToken } = props;
    const DomainRef = useRef<any>("");
    const dispatch = useDispatch();
    const otpReceived = useSelector(getSendOtp);
    const openModal = useSelector(getModalOpenStatus);
    const userValidationInfo = useSelector(getUserValidationInfo);
    document.title = FP_TAB;
    const [sendOtpError, setSendOtpError] = useState(false);
    const [sendOtpErrorMessage, setSendOtpErrorMessage] = useState('');

    const initialValues = {
        email: "",
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Email is invalid").required("Email is required"),
    });

    const modelCloseHandler = () => {
        DomainRef.current.resetForm();
        window.mtcaptcha.resetUI();
    };

    useEffect(() => {
        if (otpReceived && otpReceived.success) {
            props.setStep(2);
        }
        else if (otpReceived && !otpReceived.success) {
            if (otpReceived.errors[0].errorCode === '400') {
                setSendOtpErrorMessage(otpReceived.errors[0].errorMessage);
                setSendOtpError(true);
            }
        }
    }, [otpReceived]);

    useEffect(() => {
        if (userValidationInfo) {
            if (userValidationInfo.federatedUser) {
                dispatch(updateModalStatus(true));
                setUserType(FEDERATED_USER);
            } else if (userValidationInfo.mfaExcludedUser) {
                dispatch(updateModalStatus(true));
                setUserType(USER_MFA_EXCLUDED);
            } else {
                dispatch(loadSendOtp(captchaToken));
            }
        }
    }, [userValidationInfo]);

    const handleModelPopup = () => {
        if (userType) {
            switch (userType) {
                case USER_MFA_EXCLUDED:
                    return (
                        <ModelPopUp
                            icon="roundCross"
                            msg={USER_MFA_EXCLUDED_MSG}
                            modelCloseHandler={modelCloseHandler}
                        />
                    );

                case FEDERATED_USER:
                    return (
                        <ModelPopUp
                            icon="roundCross"
                            msg={FEDERATED_USER_MSG}
                            modelCloseHandler={modelCloseHandler}
                        />
                    );

                case INTERNAL_USER:
                    return (
                        <ModelPopUp
                            icon="roundCross"
                            msg={INTERNAL_USER_MSG}
                            modelCloseHandler={modelCloseHandler}
                        />
                    );
            }
            setUserType("");
        }
    };

    const PASSWORD_RESET_SOURCE = 'passwordReset';

    const onSubmit = (formData: any) => {
        const captchToken = (!!window.mtcaptcha && window.mtcaptcha.getVerifiedToken()) || "";
        if (!captchToken) {
            return;
        }
        if (isInternalUser(formData.email)) {
            dispatch(updateModalStatus(true));
            setUserType(INTERNAL_USER);
        }else if (isVmWareUser(formData.email)) {
            dispatch(updateModalStatus(true));
            setUserType(INTERNAL_USER);
        } else {
            props.setEmail(formData.email);
            props.setCaptchaToken(captchToken);
            dispatch(loadValidateUser(formData.email, PASSWORD_RESET_SOURCE));
        }
    };
    return (
        <React.Fragment>
            <CommonLayout>
                <ClayCard>
                    <ClayCard.Body>
                        <span className="h1">Forgot Password</span>
                        <div className="text-size-md">
                            {
                                'Enter your email address and click "Next" to reset your myBroadcom password.'
                            }
                        </div>
                        <br />
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            innerRef={DomainRef}
                            onSubmit={onSubmit}
                        >
                            {(formik) => (
                                <ClayForm onSubmit={formik.handleSubmit}>
                                    <FormikControl
                                        control="text"
                                        id="emailInputText"
                                        name="email"
                                        label="Email Address"
                                        placeholder="name@company.com"
                                    />
                                    <MTCaptcha />
                                    <ClayButton
                                        type="submit"
                                        displayType="primary"
                                        className="btn-block"
                                    >
                                        {"Next"}
                                    </ClayButton>
                                </ClayForm>
                            )}
                        </Formik>
                    </ClayCard.Body>
                </ClayCard>
            </CommonLayout>
            {openModal && handleModelPopup()}

            { sendOtpError && (
                <ModelPopUp
                    icon="cross"
                    msg={sendOtpErrorMessage}
                    modelCloseHandler={() => setSendOtpError(false)}
                />
            )}
        </React.Fragment>
    );
};
export default ForgotPassword;
