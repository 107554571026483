import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../client-src/store/hook";

import ClayPanel from "@clayui/panel";
import ClayList from "@clayui/list";
import { ClayCheckbox } from "@clayui/form";
import {
    setCheckedValues,
    getCheckedArray,
    getProfileBuilderData,
} from "../../../../../../store/Registration/profile-builder/slice";
import { IFormPanel } from "../interfaces/interfaces";

const FormPanel = (props: IFormPanel) => {
    const { idx } = props;

    const profileBuilderData = useAppSelector(getProfileBuilderData);
    const [isChecked, setIsChecked] = useState(false);
    const [item, setItem] = useState(profileBuilderData[idx]);
    const [isDisabled, setIsDisabled] = useState(item.defaultChecked);

    const dispatch = useAppDispatch();

    const checkedArray = useAppSelector(getCheckedArray);
    useEffect(() => {
        setIsChecked(!!item.defaultChecked || checkedArray[idx]);
    }, [checkedArray]);
    useEffect(() => {
        if (item.defaultChecked) {
            dispatch(setCheckedValues(idx));
        }
    }, []);
    const handleOnCheked = () => {
        setIsChecked(!isChecked);
        dispatch(setCheckedValues(idx));
    };
    return (
        <ClayPanel
            collapsable
            onExpandedChange={(type) => undefined}
            displayTitle={
                <ClayList.Item flex className="li-checkbox">
                    <ClayList.ItemField>
                        <ClayCheckbox
                            checked={isChecked}
                            disabled={isDisabled}
                            onChange={handleOnCheked}
                        />
                    </ClayList.ItemField>
                    <ClayList.ItemField className="li-field">
                        <label className="mb-1" htmlFor={item.title}>
                            {item.title}
                        </label>
                        {item.showDesc ? <small>{item.desc}</small> : null}
                    </ClayList.ItemField>
                </ClayList.Item>
            }
            expanded={isChecked}
            displayType="secondary"
        >
            {!isDisabled && (
                <ClayPanel.Body>
                    <div className="gutter-col"></div>
                    <div className="form-col">{props.children}</div>
                </ClayPanel.Body>
            )}
        </ClayPanel>
    );
};

export default FormPanel;
