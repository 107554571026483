import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../client-src/store/hook";

import ClayButton from "@clayui/button";
import {
    getCheckedArray,
    setProfileBuilderData,
    getCustomerIdSuccess,
    getSupportValSuccess,
    getProfileBuilderData,
} from "../../../../../../store/Registration/profile-builder/slice";
import { ROUTE_PATHS } from "../../../../../../utils/constants/routeConstants";
import { useSelector } from "react-redux";
import { getEnvVariables } from "../../../../../../store/global/global.slice";
import { ISetstepFunc } from "../interfaces/interfaces";
import { useLocation } from "react-router-dom";

const SubmitPanel = React.forwardRef((props: ISetstepFunc, refs) => {
    const { firstFormRef, secondFormRef, thirdFormRef, fourthFormRef, fifthFormRef, sixthFormRef } = refs;
    const dispatch = useAppDispatch();
    const checkedArray = useAppSelector(getCheckedArray);
    const customerIdSuccess = useAppSelector(getCustomerIdSuccess);
    const supportSiteIdSuccess = useAppSelector(getSupportValSuccess);
    const profileBuilderData = useAppSelector(getProfileBuilderData);
    const envVariables = useSelector(getEnvVariables);
    const supportPortalUrl = envVariables?.ui.supportPortal || ROUTE_PATHS.HOME;
    const location = useLocation();

    const handleSubmit = () => {
        let isFormValid = true;
        const submitPromiseList = [];
        let isCustomerIdSuccessTemp = true;
        let isSupportSuccessTemp = true;
        let defaultCheckedFound = false;

        if (firstFormRef.current && checkedArray[0]) {
            if (supportSiteIdSuccess === "" || supportSiteIdSuccess === null) {
                isSupportSuccessTemp = false;
            }
            submitPromiseList.push(firstFormRef.current.submitForm());
        } else if (profileBuilderData[0].defaultChecked) {
            defaultCheckedFound = true;
        }

        if (secondFormRef.current && checkedArray[1]) {
            submitPromiseList.push(secondFormRef.current.submitForm());
        } else if (profileBuilderData[1].defaultChecked) {
            defaultCheckedFound = true;
        }

        if (thirdFormRef.current && checkedArray[2]) {
            submitPromiseList.push(thirdFormRef.current.submitForm());
        } else if (profileBuilderData[2].defaultChecked) {
            defaultCheckedFound = true;
        }

        if (fourthFormRef.current && checkedArray[3]) {
            if (customerIdSuccess === "" || customerIdSuccess === null) {
                isCustomerIdSuccessTemp = false;
            }
            submitPromiseList.push(fourthFormRef.current.submitForm());
        } else if (profileBuilderData[3].defaultChecked) {
            defaultCheckedFound = true;
        }

        if (fifthFormRef.current && checkedArray[4]) {
            submitPromiseList.push(fifthFormRef.current.submitForm());
        } else if (profileBuilderData[4].defaultChecked) {
            defaultCheckedFound = true;
        }

        if (sixthFormRef.current && checkedArray[5]) {
            submitPromiseList.push(sixthFormRef.current.submitForm());
        } else if (profileBuilderData[5].defaultChecked) {
            defaultCheckedFound = true;
        }

        if (submitPromiseList.length) {
            Promise.all(submitPromiseList).then(() => {
                if (firstFormRef.current && checkedArray[0]) {
                    isFormValid = isFormValid && !Object.keys(firstFormRef.current.errors).length;
                    dispatch(setProfileBuilderData({ idx: 0, data: firstFormRef.current.values }));
                }
                if (secondFormRef.current && checkedArray[1]) {
                    isFormValid = isFormValid && !Object.keys(secondFormRef.current.errors).length;
                    dispatch(setProfileBuilderData({ idx: 1, data: secondFormRef.current.values }));
                }
                if (thirdFormRef.current && checkedArray[2]) {
                    isFormValid = isFormValid && !Object.keys(thirdFormRef.current.errors).length;
                    dispatch(setProfileBuilderData({ idx: 2, data: thirdFormRef.current.values }));
                }
                if (fourthFormRef.current && checkedArray[3]) {
                    isFormValid = isFormValid && !Object.keys(fourthFormRef.current.errors).length;
                    dispatch(setProfileBuilderData({ idx: 3, data: fourthFormRef.current.values }));
                }
                if (fifthFormRef.current && checkedArray[4]) {
                    isFormValid = isFormValid && !Object.keys(fifthFormRef.current.errors).length;
                    dispatch(setProfileBuilderData({ idx: 4, data: fifthFormRef.current.values }));
                }
                if (sixthFormRef.current && checkedArray[5]) {
                    isFormValid = isFormValid && !Object.keys(sixthFormRef.current.errors).length;
                    dispatch(setProfileBuilderData({ idx: 5, data: sixthFormRef.current.values }));
                }
                //check of Bill To Customer ID and Site ID is verified
                if (checkedArray[0] && !isSupportSuccessTemp) {
                    isFormValid = false;
                }
                if (checkedArray[3] && !isCustomerIdSuccessTemp) {
                    isFormValid = false;
                }
                if (isFormValid && checkedArray.includes(true)) {
                    props.setStep(6);
                }
            });
        } else {
            if (defaultCheckedFound) {
                props.setStep(6);
            }
        }
    };

    const handleCloseWindow = () => {
        if (location.pathname.includes("/web")) {
            window.open(supportPortalUrl, "_self");
        } else {
            window.close();
        }
    };

    return (
        <React.Fragment>
            <ClayButton
                displayType="primary"
                className="btn-block pb-1"
                onClick={() => handleSubmit()}
            >
                {"Submit"}
            </ClayButton>
            <ClayButton displayType="secondary" className="btn-block" onClick={handleCloseWindow}>
                {"Cancel"}
            </ClayButton>
        </React.Fragment>
    );
});

SubmitPanel.displayName = SubmitPanel;

export default SubmitPanel;
