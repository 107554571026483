import ClayModal, { useModal } from "@clayui/modal";
import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import { claySpriteImagePath } from "../../../../../common-ui-modules/service/util/claySpriteImagePath";
import { useSelector } from "react-redux";
import { getEnvVariables } from "../../../../../store/global/global.slice";
import "./modal.scss";
import { ROUTE_PATHS } from "../../../../../utils/constants/routeConstants";
import { IUserValidationModel } from "./interfaces/interfaces";

const UserValidationModel = (props:IUserValidationModel) => {
    const envVariables = useSelector(getEnvVariables);
    const chatBotUrl = envVariables?.ui.supportPortal || ROUTE_PATHS.HOME;
    const { observer, onClose } = useModal({
        onClose: () => props.setVisible(false),
    });

    return (
        <div>
            {props.visible && (
                <ClayModal
                    observer={observer}
                    size="md"
                    spritemap={claySpriteImagePath()}
                    status="info"
                    className="alreadyExistsModal"
                >
                    <ClayModal.Body>
                        <div className="d-flex">
                            <div className="mt-4 mr-3">
                                <p>
                                    {"User already Exists!! Please use"}
                                    <a href={chatBotUrl} target="_blank" rel="noreferrer">
                                        {" "}
                                        Broadcom Selfservice Bot{" "}
                                    </a>
                                    {"to check the current status of your account."}
                                </p>
                            </div>
                            <div>
                                <ClayButton
                                    aria-label="close"
                                    className="p-0"
                                    displayType="unstyled"
                                    onClick={onClose}
                                >
                                    <ClayIcon
                                        spritemap={claySpriteImagePath()}
                                        symbol="times-small"
                                    />
                                </ClayButton>
                            </div>
                        </div>
                    </ClayModal.Body>
                </ClayModal>
            )}
            {/* <ClayButton displ
      ayType="primary" onClick={() => setVisible(true)}>
        {"Open modal"}
      </ClayButton> */}
        </div>
    );
};

export default UserValidationModel;
