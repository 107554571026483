import React from "react";
import { TextError } from "../../../../../common-ui-modules/components/index";
import { ClayTooltipProvider } from "@clayui/tooltip";
import ClayList from "@clayui/list";
import Icon from "../../../../../common-ui-modules/components/icons/Icon";

const ServiceListItem = (props: any) => {
    const { status, title, info, classList = "" } = props;

    return (
        <React.Fragment>
            <ClayList.Item flex className={classList}>
                <ClayList.ItemField>
                    {status === "pending" ? (
                        <Icon type="roundPendingIcon" />
                    ) : (
                        <Icon type="roundTickIcon" color={status} />
                    )}
                </ClayList.ItemField>
                <ClayList.ItemField expand>{title}</ClayList.ItemField>
                {info && (
                    <ClayTooltipProvider>
                        <ClayList.ItemField
                            className="pe-cursor"
                            data-tooltip-align="top"
                            title={info}
                        >
                            <Icon type="infoIconBold" />
                        </ClayList.ItemField>
                    </ClayTooltipProvider>
                )}
            </ClayList.Item>
            {status === "pending" && (
                <TextError>
                    <span className="pending-msg">Request sent for approval</span>
                </TextError>
            )}
        </React.Fragment>
    );
};

export default ServiceListItem;
