import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../client-src/store/hook";

import ClayButton from "@clayui/button";
import ClayForm from "@clayui/form";
import ClayLayout from "@clayui/layout";
import { ClayTooltipProvider } from "@clayui/tooltip";

import { Formik } from "formik";
import { FormControl, RoundTickIcon, TextError } from "../../../../../../common-ui-modules";
import * as Yup from "yup";
import {
    setVerifySupportVal,
    getAttemptCountForCustomerId,
    resetCustomerIdSuccess,
    getCustomerIdSuccess,
    getInitialValues,
    addressValuesReceived,
    getStateVo,
    getProfileBuilderData,
    fetchStates,
    stateVOReceived,
} from "../../../../../../store/Registration/profile-builder/slice";
import {
    getCountryList,
    getCountryValueList,
} from "../../../../../../store/Registration/registration/slice";
import { MAX_40, MIN_2, POSTAL_CODE_REGEX, SPECIAL_CHARS } from "../../constants";
import { MAX_255 } from "../../../pages/my-profile/UserInformation/constants";
import { checkIfStatePresent } from "../../../../../../utils/utils";

const Form4 = React.forwardRef((props, fourthFormRef) => {
    const [isValid, setIsValid] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [initialFormObj, setInitialFormObj] = useState({});
    const [showInvalidMsg, setShowInvalidMsg] = useState(false);

    const dispatch = useAppDispatch();

    const initialObj = useAppSelector(getInitialValues);
    const profileData = useAppSelector(getProfileBuilderData);
    let stateOptions: any = [];
    stateOptions = useAppSelector(getStateVo);
    const countryOptions = useAppSelector(getCountryList);
    const countryOptionValues = useAppSelector(getCountryValueList);

    const attemptCount = useAppSelector(getAttemptCountForCustomerId);
    const customerIdSuccess = useAppSelector(getCustomerIdSuccess);

    useEffect(() => {
        if (customerIdSuccess === "" || customerIdSuccess === null) {
            setIsValid(false);
            setShowVerify(false);
            setShowInvalidMsg(true);
        } else {
            setIsValid(true);
        }
    }, [customerIdSuccess]);

    useEffect(() => {
        const state = initialObj.addressValues.state;
        fourthFormRef.current.setFieldValue("phone", initialObj.addressValues.phone);
        fourthFormRef.current.setFieldValue("companyId", initialObj.addressValues.companyId);
        fourthFormRef.current.setFieldValue("companyName", initialObj.addressValues.companyName);
        fourthFormRef.current.setFieldValue("address", initialObj.addressValues.address);
        fourthFormRef.current.setFieldValue("address2", initialObj.addressValues.address2);
        fourthFormRef.current.setFieldValue("city", initialObj.addressValues.city);
        fourthFormRef.current.setFieldValue("zipcode", initialObj.addressValues.zipcode);
        fourthFormRef.current.setFieldValue(
            "state",
            checkIfStatePresent(stateOptions, state) ? state : "",
        );
        fourthFormRef.current.setFieldValue("country", initialObj.addressValues.country);
    }, [initialObj]);

    useEffect(() => {
        let obj = {};
        if (profileData[3].data) {
            obj = { ...profileData[3].data };
        } else {
            obj = { customerId: "", ...initialObj.addressValues };
        }
        setInitialFormObj(obj);
    }, []);
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const validationSchema = Yup.object().shape({
        customerId: Yup.string().required("*Customer Id is required"),
        phone: Yup.string()
            .required("*Phone Number is required")
            .min(8, "A minimum of 8 character is required")
            .max(40, MAX_40)
            .matches(phoneRegExp, "Phone number is not valid"),
        address: Yup.string().required("*Address is required").min(2, MIN_2).max(255, MAX_255),
        address2: Yup.string()
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ0-9.,\s]+$/, SPECIAL_CHARS),
        city: Yup.string()
            .required("*City is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ\s]+$/, "Numbers & special characters are not allowed"),
        zipcode: Yup.string()
            .required("*Zip/Postal Code is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(POSTAL_CODE_REGEX, SPECIAL_CHARS),
        country: Yup.string()
            .oneOf(countryOptionValues, "Country is required")
            .required("Country is required"),
        companyName: Yup.string().required("*Company is required"),
        companyId: Yup.string().required("*Company is required"),
    });

    const handleCompanyChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ companyName: fourthFormRef.current.values.companyName }));
    };
    const handleContactChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ phone: fourthFormRef.current.values.phone }));
    };

    const handleAddressChange = (value, formik, line) => {
        formik.handleBlur(value);
        if (line === 2) {
            dispatch(addressValuesReceived({ address2: fourthFormRef.current.values.address2 }));
        } else {
            dispatch(addressValuesReceived({ address: fourthFormRef.current.values.address }));
        }
    };

    const handleZipChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ zipcode: fourthFormRef.current.values.zipcode }));
    };
    const handleCityChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ city: fourthFormRef.current.values.city }));
    };
    const handleStateChange = (e, formik) => {
        formik.handleChange(e);
        fourthFormRef.current.setFieldValue("state", e.currentTarget.value);
        fourthFormRef.current.values.state = e.currentTarget.value;
        dispatch(addressValuesReceived({ state: fourthFormRef.current.values.state }));
    };
    const handleCompanySelectChange = (e, formik) => {
        formik.handleChange(e);
        fourthFormRef.current.setFieldValue("companyId", e.currentTarget.value);
        fourthFormRef.current.values.companyId = e.currentTarget.value;
        dispatch(addressValuesReceived({ companyId: fourthFormRef.current.values.companyId }));
    };
    const handleOnChangeCountry = (e, formik) => {
        dispatch(stateVOReceived([{ label: "Select State", value: "" }]));
        formik.handleChange(e);
        fourthFormRef.current.setFieldValue("stateName", "");
        fourthFormRef.current.setFieldValue("country", e.currentTarget.value);
        fourthFormRef.current.setFieldValue(
            "countryName",
            e.currentTarget.options[e.currentTarget.selectedIndex].text,
        );
        dispatch(addressValuesReceived({ country: e.currentTarget.value }));
        dispatch(
            addressValuesReceived({
                countryName: e.currentTarget.options[e.currentTarget.selectedIndex].text,
            }),
        );
        dispatch(fetchStates(e.currentTarget.value));
    };
    const verifySupportVal = () => {
        //handleCustomerIdChange()
        const customerID = fourthFormRef.current.values.customerId.trim();
        fourthFormRef.current.setFieldValue("customerId", customerID);
        const data = {
            supportTypeId: "2",
            supportTypeVal: customerID,
        };
        dispatch(setVerifySupportVal(data, "customerId"));
    };

    const validateStateIfPresent = (value) => {
        let errors = "";
        if (
            (stateOptions.length > 1 && value === "") ||
            (stateOptions.length > 1 && value && !stateOptions.map((i) => i.value).includes(value))
        ) {
            errors = "State is required.";
        }
        return errors;
    };
    const handleKeyDown = () => {
        setShowInvalidMsg(false);
        if (attemptCount !== 0) {
            setShowVerify(true);
            dispatch(resetCustomerIdSuccess());
        }
    };
    const attempts = [
        "Invalid Customer Id. 2 attempts remaining",
        "Invalid Customer Id. 1 attempt remaining",
        "Sorry no attempts remaining",
    ];
    return (
        <React.Fragment>
            <Formik
                innerRef={fourthFormRef}
                enableReinitialize={"true"}
                destroyOnUnmount={"false"}
                initialValues={initialFormObj}
                validationSchema={validationSchema}
                onSubmit={() => {
                    /**/
                }}
            >
                {(formik) => (
                    // formik : ({ values, errors, handleSubmit, handleChange, handleBlur })
                    <ClayForm onSubmit={formik.handleSubmit}>
                        <ClayLayout.Row className="supportSiteIdrow">
                            <ClayLayout.Col size={8}>
                                <label htmlFor="siteId">Bill To Customer ID</label>
                            </ClayLayout.Col>
                        </ClayLayout.Row>
                        <ClayLayout.Row className="">
                            <ClayLayout.Col size={8}>
                                <FormControl
                                    control="text"
                                    type="text"
                                    name="customerId"
                                    autoComplete={"off"}
                                    placeholder="Eg. 435356546"
                                    onKeyDown={handleKeyDown}
                                    onPaste={() => setShowVerify(true)}
                                    disabled={attemptCount === 0 ? true : false}
                                />
                                {formik.errors.customerId
                                    ? null
                                    : attemptCount !== 3 &&
                                      showInvalidMsg && (
                                          <div className="has-error">
                                              <TextError>
                                                  {attemptCount === 2
                                                      ? attempts[0]
                                                      : attemptCount === 1
                                                      ? attempts[1]
                                                      : attemptCount === 0 && attempts[2]}
                                              </TextError>
                                          </div>
                                      )}
                            </ClayLayout.Col>
                            <ClayLayout.Col size={4} className="">
                                {showVerify && !isValid ? (
                                    <ClayButton displayType="primary" onClick={verifySupportVal}>
                                        {"Verify"}
                                    </ClayButton>
                                ) : isValid ? (
                                    <RoundTickIcon color="success" />
                                ) : customerIdSuccess === null ? (
                                    ""
                                ) : (
                                    ""
                                )}
                            </ClayLayout.Col>
                        </ClayLayout.Row>
                        <ClayTooltipProvider>
                            <ClayButton
                                className="pl-0"
                                displayType="link"
                                data-tooltip-align="right"
                                title={"Please contact your Broadcom Sales Representative"}
                                style={{ color: " rgb(0, 92, 138)" }}
                            >
                                Need help locating your Bill to Customer ID?
                            </ClayButton>
                        </ClayTooltipProvider>
                        {initialObj &&
                        initialObj.company &&
                        initialObj.company.fieldType === "select" ? (
                            <FormControl
                                control="select"
                                name="companyId"
                                label="Company"
                                options={initialObj.company.options}
                                onChange={(e) => handleCompanySelectChange(e, formik)}
                                disabled={
                                    initialObj &&
                                    initialObj.company &&
                                    initialObj.company.isDisabled
                                }
                            />
                        ) : (
                            <FormControl
                                control="text"
                                name="companyName"
                                label="Company"
                                onBlur={(e) => handleCompanyChange(e, formik)}
                                disabled={
                                    initialObj &&
                                    initialObj.company &&
                                    initialObj.company.isDisabled
                                }
                            />
                        )}
                        <FormControl
                            control="text"
                            type="text"
                            name="phone"
                            label="Phone Number"
                            autoComplete={"off"}
                            placeholder="Enter the contact number"
                            onBlur={(e) => handleContactChange(e, formik)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="address"
                            label="Address Line 1"
                            autoComplete={"off"}
                            placeholder="Enter the address line 1"
                            onBlur={(e) => handleAddressChange(e, formik)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="address2"
                            label="Address Line 2"
                            autoComplete={"off"}
                            placeholder="Enter the address line 2"
                            onBlur={(e) => handleAddressChange(e, formik, 2)}
                        />

                        <FormControl
                            control="text"
                            type="text"
                            name="city"
                            label="City"
                            autoComplete={"off"}
                            placeholder="Enter the city name"
                            onBlur={(e) => handleCityChange(e, formik)}
                        />
                        <FormControl
                            control="select"
                            name="country"
                            label="Country"
                            options={countryOptions}
                            onChange={(e) => handleOnChangeCountry(e, formik)}
                            onBlur={() => formik.setFieldTouched("country", true)}
                        />
                        {stateOptions.length > 1 && (
                            <FormControl
                                control="select"
                                name="state"
                                label="State"
                                autoComplete={"off"}
                                options={stateOptions}
                                validate={validateStateIfPresent}
                                onChange={(e) => handleStateChange(e, formik)}
                            />
                        )}
                        <FormControl
                            control="text"
                            type="text"
                            name="zipcode"
                            label="Zip/Postal Code"
                            autoComplete={"off"}
                            placeholder="Enter the zip/postal code"
                            onBlur={(e) => handleZipChange(e, formik)}
                        />
                    </ClayForm>
                )}
            </Formik>
        </React.Fragment>
    );
});
Form4.displayName = "Form4";

export default Form4;
