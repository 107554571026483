import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../../../common-ui-modules/service/redux-helper/api-action-creator";
import { FORGOT_PASSWORD_API_URL } from "./constants";

const slice = createSlice({
    name: "forgetPassword",
    initialState: {
        mailRequestSend: false,
        openModal: false,
        sendOtp: null,
        validateOtp: null,
        ValidateOtpFailed: false,
        errorMsg: "",
        userValidationInfo: null,
    },
    reducers: {
        forgetPasswordMailSent: (state, action) => {
            state.mailRequestSend = action.payload.data && action.payload.success;
            state.openModal = action.payload.data && action.payload.success;
        },
        resetState: (state) => {
            state.mailRequestSend = false;
        },
        userNotRegistered: (state, action) => {
            state.errorMsg = action.payload.errors[0].errorMessage;
            state.openModal = !action.payload.success;
        },
        resetErrorMsg: (state) => {
            state.errorMsg = "";
        },
        updateModalStatus: (state, action) => {
            state.openModal = action.payload;
        },
        sendOtpReceived: (state, action) => {
            state.sendOtp = action.payload;
            state.openModal = action.payload.data && action.payload.success;
        },
        resetSendOtp: (state) => {
            state.sendOtp = null;
        },
        validateOtpReceived: (state, action) => {
            state.validateOtp = action.payload;
        },
        otpValidationFailed: (state, action) => {
            state.ValidateOtpFailed = !action.payload.success;
        },
        resetValidateOtpReceived: (state, action) => {
            state.validateOtp = action.payload;
        },
        resetOtpValidationFailed: (state) => {
            state.ValidateOtpFailed = false;
        },
        validateUserReceived: (state, action) => {
            if (action.payload) {
                state.userValidationInfo = action.payload.data;
            }
        },
    },
});

export const {
    forgetPasswordMailSent,
    resetState,
    userNotRegistered,
    sendOtpReceived,
    resetSendOtp,
    validateOtpReceived,
    otpValidationFailed,
    resetValidateOtpReceived,
    resetOtpValidationFailed,
    updateModalStatus,
    resetErrorMsg,
    validateUserReceived,
} = slice.actions;

//custom actions
export const sendForgetPassword = (email: string, captchaToken: string) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: FORGOT_PASSWORD_API_URL.FORGOT_PASSWORD,
            method: "POST",
            data: { email: email, captchaToken: captchaToken },
            onSuccess: forgetPasswordMailSent.type,
            skipErrorHandling: true,
            onError: userNotRegistered.type,
        }),
    );
};

export const loadSendOtp = (captchaToken: string | null) => (dispatch: any) => {
    dispatch(loadResetValidateOtp());
    return dispatch(
        apiCallBegan({
            url: FORGOT_PASSWORD_API_URL.SEND_OTP,
            method: "POST",
            data: {
                verificationCode: captchaToken,
                source: "forgotPassword", //TODO: constants
                captchaToken: "",
            },
            onSuccess: sendOtpReceived.type,
            skipErrorHandling: true,
            onError: sendOtpReceived.type,
        }),
    );
};

export const loadValidateOtp =
    (verificationCode: string, captchaToken: string) => (dispatch: any) => {
        return dispatch(
            apiCallBegan({
                url: FORGOT_PASSWORD_API_URL.VALIDATE_OTP,
                method: "POST",
                data: {
                    verificationCode: verificationCode,
                    source: "forgotPassword",
                },
                onSuccess: validateOtpReceived.type,
                skipErrorHandling: true,
                onError: otpValidationFailed.type,
            }),
        );
    };

export const loadValidateUser = (email: string, source: string) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: FORGOT_PASSWORD_API_URL.VALIDATE_USER,
            method: "POST",
            data: { emailId: email, source: source },
            onSuccess: validateUserReceived.type,
        }),
    );
};

export const loadResetValidateOtp = () => (dispatch: any) => {
    dispatch(resetValidateOtpReceived(null));
};

//selectors
export const getMailRequestSent = (state: any) => state.forgetPassword.mailRequestSend;
export const getModalOpenStatus = (state: any) => state.forgetPassword.openModal;
export const getSendOtp = (state: any) => state.forgetPassword.sendOtp;
export const getOtpValidationStatus = (state: any) => state.forgetPassword.ValidateOtpFailed;
export const getValidateOtp = (state: any) => state.forgetPassword.validateOtp;
export const getErrorMsg = (state: any) => state.forgetPassword.errorMsg;
export const getUserValidationInfo = (state: any) => state.forgetPassword.userValidationInfo;

// reducer
export const forgetPasswordReducer = slice.reducer;
